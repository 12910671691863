import type { SiteFormsKey } from '@/types'
import { env } from '@/store/env'

/**
 * Функция преобразования camel to kebab
 *
 * @param camel
 * @returns
 */
export function camelToKebab(camel: string): string {
  return camel
    .replace(/([a-z])([A-Z])/g, '$1-$2') // Добавляем дефис между маленькой и заглавной буквой
    .replace(/([0-9])([A-Z])/g, '$1-$2') // Добавляем дефис между цифрой и заглавной буквой
    .replace(/([A-Z])([A-Z])/g, '$1-$2') // Добавляем дефис между двумя заглавными буквами
    .toLowerCase() // Приводим к нижнему регистру
}

/**
 * Функция преобразования kebab to camel
 *
 * @param kebab
 * @returns
 */
export function kebabToCamel(kebab: string): string {
  return kebab
    .split('-') // Разделяем строку по дефисам
    .map((word, index) => {
      // Приводим первую букву каждого слова к заглавной, кроме первого
      if (index === 0) {
        return word // Первое слово остаётся в нижнем регистре
      }
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('') // Объединяем обратно в строку
}

/**
 * Функция замены в строке кириллицы на транслитерацию
 *
 * @param cyrillic
 * @returns
 */
export function cyrillicToLatin(cyrillic: string) {
  const cyrA = cyrillic.split('')
  let lat = ''
  for (const charC of cyrA) {
    const [, charL] = env.transliteration.find((c) => c[0] === charC) ?? []
    if (charL) lat += charL
    else lat += charC
  }
  return lat
}

/**
 * Trim для произвольного символа
 *
 * @param str
 * @param char
 * @returns
 */
export function trimCharacter(str: string, char: string): string {
  const regex = new RegExp(`^[${char}]+|[${char}]+$`, 'g')
  return str.replace(regex, '')
}

/**
 * multi to single для произвольного символа
 *
 * @param str
 * @param char
 * @returns
 */
export function multiToSingleCharacter(str: string, char: string): string {
  const regex = new RegExp(`[${char}]+`, 'g')
  return str.replace(regex, `${char}`)
}

/**
 * Функция преобразования ключа структуры сайта в lat kebab
 *
 * @param key
 */
export function keyToKebab(key: string) {
  // оставляем только [a-zA-Z0-9] и пробелы, заменяем пробелы на единичный -, тримим по -
  return trimCharacter(
    cyrillicToLatin(key).replace(env.regexes.azAZ09, ' ').replace(env.regexes.multispace, '-'),
    '-'
  )
}

/**
 * Функция преобразования ключа структуры сайта в lat camel
 *
 * @param key
 */
export function keyToCamel(key: string) {
  // оставляем только [a-zA-Z0-9] и пробелы, сокращаем пробелы
  return kebabToCamel(keyToKebab(key))
}

/**
 * Делаем первый символ upercase
 *
 * @param str
 * @returns
 */
export function strCap(str: string | undefined) {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Засыпаем на ms
 *
 * @param ms
 * @returns
 */
export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function priceRub(value: number): string {
  return value.toLocaleString('ru-RU').replace(/,/g, ' ') + ' ₽'
}

export function lsKey(formKey: SiteFormsKey) {
  return `p5s_${formKey}`
}
