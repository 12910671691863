<script setup lang="ts">
import type { SiteItemKeys } from '@/types'
import { siteRouter } from '@/router'
import { computed } from 'vue'
import SiteItemComponent from '../elements/SiteItemComponent.vue'

const menu = computed(() => {
  const entry = Object.entries(siteRouter.activeMenuFooterItemEntries).find(
    ([, v]) => (v as any)[siteRouter.currentRoute.value!.alias!]
  )
  if (!entry) return undefined
  const items = entry[1]
  if (typeof items === 'boolean') return undefined
  return {
    folder: entry[0],
    items: Object.keys(items) as SiteItemKeys[]
  }
})
</script>
<template>
  <div v-if="menu" class="LeftMenu">
    <div class="banner-name" v-html="menu.folder" />
    <SiteItemComponent
      :key="`${menu.folder}_${k}`"
      v-for="[k, item] of menu.items.entries()"
      :site-key="item"
    />
  </div>
</template>
<style lang="scss" scoped>
.LeftMenu {
  background-color: $bg-light-blue;
  padding-top: 15px;
  height: 100%;
  > * {
    display: block;
    color: $text-primary;
    padding: 15px 30px;
  }
  > a {
    font-size: clamp(15px, 100vw / (1300 / 18), 18px);
    &.router-link-active {
      font-weight: 700;
    }
  }
}
</style>
