import './assets/css/variables.css'

import { createApp } from 'vue'
import App from './App.vue'
import { Store } from './store/store'
import router from './router'

const app = createApp(App)
const store = new Store()
app.config.globalProperties.$store = store
app.use(router)

app.mount('#app')
