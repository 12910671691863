<script setup lang="ts">
import HorizontalSliderComponent from '@/components/widgets/SliderComponent.vue'
import NewsItemComponent from '@/components/widgets/NewsItemComponent.vue'
import type { NewsItem } from '@/types'
const items: NewsItem[] = [
  {
    image: '1.webp',
    topic: 'Расширяем ассортимент нижнего белья 1',
    date: '6 июня 2021',
    anonce:
      'Нижнее белье как часть большого сегмента fashion в фокусе внимания всех ключевых российских маркетплейсов. Поддерживая этот тренд, «Поставщик счастья» также развивает ассортимент категории.'
  },
  {
    image: '1.webp',
    topic: 'Расширяем ассортимент нижнего белья 2',
    date: '7 июня 2021',
    anonce:
      'Нижнее белье как часть большого сегмента fashion в фокусе внимания всех ключевых российских маркетплейсов. Поддерживая этот тренд, «Поставщик счастья» также развивает ассортимент категории.'
  },
  {
    image: '1.webp',
    topic: 'Расширяем ассортимент нижнего белья 3',
    date: '8 июня 2021',
    anonce:
      'Нижнее белье как часть большого сегмента fashion в фокусе внимания всех ключевых российских маркетплейсов. Поддерживая этот тренд, «Поставщик счастья» также развивает ассортимент категории.'
  },
  {
    image: '1.webp',
    topic: 'Расширяем ассортимент нижнего белья 4',
    date: '9 июня 2021',
    anonce:
      'Нижнее белье как часть большого сегмента fashion в фокусе внимания всех ключевых российских маркетплейсов. Поддерживая этот тренд, «Поставщик счастья» также развивает ассортимент категории.'
  },
  {
    image: '1.webp',
    topic: 'Расширяем ассортимент нижнего белья 5',
    date: '10 июня 2021',
    anonce:
      'Нижнее белье как часть большого сегмента fashion в фокусе внимания всех ключевых российских маркетплейсов. Поддерживая этот тренд, «Поставщик счастья» также развивает ассортимент категории.'
  },
  {
    image: '1.webp',
    topic: 'Расширяем ассортимент нижнего белья 6',
    date: '11 июня 2021',
    anonce:
      'Нижнее белье как часть большого сегмента fashion в фокусе внимания всех ключевых российских маркетплейсов. Поддерживая этот тренд, «Поставщик счастья» также развивает ассортимент категории.'
  }
]
</script>
<template>
  <div class="News">
    <div class="horizontal-limiter">
      <HorizontalSliderComponent name="Сводка новостей" class="_desk">
        <NewsItemComponent :key="k" v-for="[k, item] of items.entries()" v-bind="item" />
      </HorizontalSliderComponent>
      <HorizontalSliderComponent name="Сводка новостей" class="_mob">
        <div class="_item">
          <NewsItemComponent
            :key="k"
            v-for="[k, item] of items.slice(0, 2).entries()"
            v-bind="item"
          />
        </div>
        <div class="_item">
          <NewsItemComponent
            :key="k"
            v-for="[k, item] of items.slice(2, 4).entries()"
            v-bind="item"
          />
        </div>
        <div class="_item">
          <NewsItemComponent
            :key="k"
            v-for="[k, item] of items.slice(4, 6).entries()"
            v-bind="item"
          />
        </div>
      </HorizontalSliderComponent>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.News {
  background-color: $bg-medium;
  padding-top: clamp(30px, calc(100vw / 1400 * 60), 60px);
  padding-bottom: clamp(30px, calc(100vw / 1400 * 60), 60px);
  @media (max-width: 480px) {
    ._mob {
      display: flex;
    }
    ._desk {
      display: none;
    }
    ._item {
      width: 100%;
    }
  }
  @media (min-width: 481px) {
    ._mob {
      display: none;
    }
    ._desk {
      display: flex;
    }
  }
  :deep(*) {
    color: $text-contrast-inverse;
  }
  :deep(.Slider) {
    gap: 5px;
  }
}
</style>
