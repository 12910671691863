<script setup lang="ts">
import HorizontalSliderComponent from '@/components/widgets/SliderComponent.vue'
import AboutUsItemComponent from '@/components/widgets/AboutUsItemComponent.vue'
import type { AboutUsItem } from '@/types'

const items: AboutUsItem[] = [
  {
    image: '1.webp',
    topic: '«Россияне на самоизоляции накупили секс-игрушек» 1',
    date: '7 июня 2021',
    source: 'Lenta.ru',
    url: 'https://lenta.ru'
  },
  {
    image: '1.webp',
    topic: '«Россияне на самоизоляции накупили секс-игрушек» 2',
    date: '8 июня 2021',
    source: 'Lenta.ru',
    url: 'https://lenta.ru'
  },
  {
    image: '1.webp',
    topic: '«Россияне на самоизоляции накупили секс-игрушек» 3',
    date: '9 июня 2021',
    source: 'Lenta.ru',
    url: 'https://lenta.ru'
  },
  {
    image: '1.webp',
    topic: '«Россияне на самоизоляции накупили секс-игрушек» 4',
    date: '10 июня 2021',
    source: 'Lenta.ru',
    url: 'https://lenta.ru'
  },
  {
    image: '1.webp',
    topic: '«Россияне на самоизоляции накупили секс-игрушек» 5',
    date: '11 июня 2021',
    source: 'Lenta.ru',
    url: 'https://lenta.ru'
  },
  {
    image: '1.webp',
    topic: '«Россияне на самоизоляции накупили секс-игрушек» 6',
    date: '12 июня 2021',
    source: 'Lenta.ru',
    url: 'https://lenta.ru'
  }
]
</script>
<template>
  <div class="AboutUs">
    <div class="horizontal-limiter">
      <HorizontalSliderComponent class="_list" name="О нас говорят" orientation="horizontal">
        <div class="_item">
          <AboutUsItemComponent
            :key="k"
            v-for="[k, item] of items.slice(0, 3).entries()"
            v-bind="item"
          />
        </div>
        <div class="_item">
          <AboutUsItemComponent
            :key="k"
            v-for="[k, item] of items.slice(3, 6).entries()"
            v-bind="item"
          />
        </div>
      </HorizontalSliderComponent>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.AboutUs {
  padding-top: clamp(30px, calc(100vw / 1400 * 60), 60px);
  padding-bottom: clamp(30px, calc(100vw / 1400 * 60), 60px);
  ._item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  ._list {
    // height: 300px;
  }
  @media (max-width: 480px) {
  }
}
</style>
