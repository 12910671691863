<script setup lang="ts">
import { siteRouter } from './router'
import HeaderComponent from './components/header/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
import { strCap } from './lib/utils'
import BreadcrumbComponent from './components/widgets/BreadcrumbComponent.vue'
import MapIconComponent from './components/icons/MapIconComponent.vue'
import LeftMenuComponent from './components/widgets/LeftMenuComponent.vue'
import { onBeforeMount, onBeforeUnmount } from 'vue'
import { useStore } from './store/useStory'

const store = useStore()

onBeforeMount(async () => {
  document.body.classList.remove('pre-load')
  await store.init()
})

onBeforeUnmount(async () => {
  await store.destroy()
})
</script>

<template>
  <HeaderComponent />
  <section
    class="App"
    :class="{
      'horizontal-limiter': !siteRouter.currentRoute.value?.hide?.limiter,
      '_with-left-menu': !siteRouter.currentRoute.value?.hide?.leftMenu
    }"
    v-if="siteRouter.currentRoute.value"
  >
    <aside class="_left-menu" v-if="!siteRouter.currentRoute.value.hide?.leftMenu">
      <LeftMenuComponent />
    </aside>
    <main>
      <BreadcrumbComponent v-if="!siteRouter.currentRoute.value.hide?.breadcrumb" />
      <h1
        v-if="!siteRouter.currentRoute.value.hide?.h1"
        v-html="strCap(siteRouter.currentRoute.value?.alias)"
      />
      <RouterView class="_main-content" />
    </main>
  </section>
  <FooterComponent />
  <MapIconComponent />
</template>

<style scoped lang="scss">
.App {
  min-height: calc(100vh - 287px);
  &.horizontal-limiter._with-left-menu {
    display: flex;
    padding: 0 clamp(15px, 10.28vw, 148px) 0 clamp(15px, 10.28vw, 118px);
    ._left-menu {
      width: clamp(223px, 100vw / (1300 / 294), 294px);
      flex-shrink: 0;
      display: block;
      @media (max-width: 900px) {
        display: none;
      }
    }
    main {
      flex-grow: 1;
      padding: 30px 0 50px clamp(15px, 100vw / 28, 50px);
      width: calc(
        #{'100vw - clamp(15px, 10.28vw, 148px) - clamp(15px, 10.28vw, 118px) - clamp(223px, 100vw / (1300 / 294), 294px)'}
      );
      h1 {
        margin: 30px 0;
      }
      ._main-content {
        display: flex;
        flex-direction: column;
        gap: clamp(30px, 100vw / (1440 / 60), 60px);
      }
    }
    @media (max-width: 600px) {
      padding: 0 15px 30px 15px;
      main {
        padding: 0;
      }
    }
  }
}
</style>
