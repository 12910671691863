<script setup lang="ts">
import type { NewsItem } from '@/types'

const props = defineProps<NewsItem>()
</script>
<template>
  <div class="NewsItem">
    <div class="_img" :style="{ backgroundImage: `url(/img/news/80/${props.image})` }" />
    <div class="_content">
      <h4 v-html="props.topic" style="order: 20" />
      <div class="caption" v-html="props.date" style="order: 10" />
      <div class="body3" v-html="props.anonce" style="order: 30" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.NewsItem {
  width: 50%;
  display: flex;
  gap: 15px;
  ._img {
    flex-shrink: 0;
    width: 80px;
    background-repeat: no-repeat;
    background-size: 80px;
    margin-top: 7px;
  }
  ._content {
    flex-grow: 1;
  }
  h4 {
    margin-bottom: 30px;
  }
  .caption {
    margin-bottom: 10px;
  }
  @media (max-width: 1000px) {
    width: 75%;
  }
  @media (max-width: 800px) {
    width: 90%;
  }
  @media (max-width: 480px) {
    width: 100%;
    padding: 20px 0;
    border-bottom: solid 1px $bg-light;
    ._img {
      display: none;
    }
    ._content {
      display: flex;
      flex-direction: column;
    }
    h4 {
      margin-bottom: 10px;
    }
    .caption {
      margin-bottom: 5px;
    }
  }
}
</style>
