import type { IconDescription } from '../types'

const transliteration = {
  // транслитерации
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'yo',
  ж: 'zh',
  з: 'z',
  и: 'i',
  й: 'j',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'ts',
  ч: 'ch',
  ш: 'sh',
  щ: 'shch',
  ь: '',
  ы: 'y',
  ъ: '',
  э: 'e',
  ю: 'yu',
  я: 'ya',

  А: 'A',
  Б: 'B',
  В: 'V',
  Г: 'G',
  Д: 'D',
  Е: 'E',
  Ë: 'Yo',
  Ж: 'Zh',
  З: 'Z',
  И: 'I',
  Й: 'J',
  К: 'K',
  Л: 'L',
  М: 'M',
  Н: 'N',
  О: 'O',
  П: 'P',
  Р: 'R',
  С: 'S',
  Т: 'T',
  У: 'U',
  Ф: 'F',
  Х: 'H',
  Ц: 'Ts',
  Ч: 'Ch',
  Ш: 'Sh',
  Щ: 'Shch',
  Ь: '',
  Ы: 'Y',
  Ъ: '',
  Э: 'E',
  Ю: 'Yu',
  Я: 'Ya'
}

export const htmlColors = [
  // именованные html-цвета
  // Основные цвета
  'red',
  'green',
  'blue',
  'yellow',
  'black',
  'white',

  // Оттенки красного
  'maroon',
  'crimson',
  'firebrick',
  'darkred',
  'indianred',
  'lightcoral',
  'salmon',
  'darksalmon',
  'coral',
  'tomato',
  'orangered',
  'red',
  'darkred',

  // Оттенки оранжевого
  'orange',
  'darkorange',
  'goldenrod',
  'gold',

  // Оттенки желтого
  'yellow',
  'lightyellow',
  'lemonchiffon',
  'lightgoldenrodyellow',
  'papayawhip',
  'moccasin',
  'peachpuff',
  'palegoldenrod',
  'khaki',
  'darkkhaki',

  // Оттенки зеленого
  'lime',
  'green',
  'limegreen',
  'forestgreen',
  'olivedrab',
  'yellowgreen',
  'springgreen',
  'mediumspringgreen',
  'lawngreen',
  'chartreuse',
  'aquamarine',
  'turquoise',
  'teal',

  // Оттенки синего
  'blue',
  'mediumblue',
  'darkblue',
  'navy',
  'midnightblue',
  'royalblue',
  'dodgerblue',
  'deepskyblue',
  'lightskyblue',
  'skyblue',
  'lightblue',
  'powderblue',

  // Оттенки фиолетового
  'purple',
  'mediumpurple',
  'darkviolet',
  'darkorchid',
  'indigo',
  'violet',
  'plum',
  'orchid',

  // Оттенки коричневого
  'brown',
  'saddlebrown',
  'peru',
  'chocolate',
  'tan',

  // Серые оттенки
  'gray',
  'silver',
  'darkgray',
  'lightgray',
  'gainsboro',

  // Другие цвета
  'pink',
  'fuchsia',
  'magenta',
  'slategray',
  'slateblue',
  'olive',
  'lightpink',
  'darkorange',
  'lightseagreen',
  'mediumvioletred',
  'coral',
  'hotpink',
  'dodgerblue',
  'lightcyan',
  'mistyrose',
  'whitesmoke',
  'lavender',
  'honeydew',

  // Используемые замены
  'currentColor'
] as const

const icons = {
  // спецификации иконок
  'svg-logo': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 250,
      h: 400
    },
    zoom: 0.1,
    symbol: false
  },
  'svg-slogan': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 1224,
      h: 148
    },
    zoom: 0.1,
    symbol: false
  },
  'svg-b2b2c': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 418,
      h: 96
    },
    zoom: 0.1,
    symbol: false
  },
  'svg-catalog': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 20,
      h: 20
    },
    symbol: `<path fill="currentColor" d="M5.625 2.083a3.542 3.542 0 0 1 3.542 3.542v3.542H5.625a3.542 3.542 0 0 1 0-7.084ZM7.5 7.5V5.625A1.875 1.875 0 1 0 5.625 7.5H7.5Zm-1.875 3.333h3.542v3.542a3.542 3.542 0 1 1-3.542-3.542Zm0 1.667A1.875 1.875 0 1 0 7.5 14.375V12.5H5.625Zm8.75-10.417a3.542 3.542 0 0 1 0 7.084h-3.542V5.625a3.542 3.542 0 0 1 3.542-3.542Zm0 5.417A1.875 1.875 0 1 0 12.5 5.625V7.5h1.875Zm-3.542 3.333h3.542a3.542 3.542 0 1 1-3.542 3.542v-3.542ZM12.5 12.5v1.875a1.875 1.875 0 1 0 1.875-1.875H12.5Z"/>`
  },
  'svg-telegram': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 20,
      h: 20
    },
    symbol: `<path fill-rule="evenodd" d="M3.367 9.113c4.129-1.8 6.882-2.985 8.26-3.558 3.933-1.636 4.75-1.92 5.283-1.93.118-.002.38.027.55.165a.596.596 0 0 1 .2.383c.02.11.043.362.024.558-.213 2.24-1.135 7.675-1.604 10.183-.199 1.061-.59 1.417-.968 1.452-.823.076-1.447-.543-2.244-1.066-1.246-.817-1.95-1.326-3.16-2.123-1.4-.921-.493-1.428.304-2.256.209-.216 3.833-3.513 3.903-3.812.009-.037.017-.177-.066-.25-.083-.074-.205-.049-.293-.029-.125.029-2.117 1.345-5.974 3.95-.566.387-1.078.577-1.536.567-.506-.011-1.479-.286-2.202-.521-.887-.289-1.592-.441-1.53-.93.031-.256.382-.517 1.053-.783Z" clip-rule="evenodd"/>`
  },
  'svg-telegram-circle': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 20,
      h: 20
    },
    symbol: `<g clip-path="url(#a)"><path fill="currentColor" d="M10.2 20c5.523 0 10-4.477 10-10s-4.477-10-10-10S.2 4.477.2 10s4.477 10 10 10Z"/><path fill="var(--social-color)" fill-rule="evenodd" d="M4.727 9.894c2.915-1.27 4.859-2.107 5.832-2.512 2.777-1.155 3.354-1.356 3.73-1.362.083-.002.268.019.387.116a.421.421 0 0 1 .143.27c.013.079.03.256.016.394-.15 1.582-.801 5.419-1.133 7.19-.14.75-.416 1-.683 1.025-.58.053-1.022-.384-1.584-.752-.88-.577-1.378-.937-2.232-1.5-.987-.65-.347-1.008.216-1.592.147-.153 2.705-2.48 2.755-2.691.006-.027.012-.125-.046-.177-.059-.052-.145-.034-.207-.02-.089.02-1.495.949-4.219 2.788-.399.274-.76.407-1.084.4-.357-.008-1.044-.202-1.554-.368-.627-.203-1.124-.31-1.081-.656.022-.18.27-.365.744-.553Z"clip-rule="evenodd"/></g><defs><clipPath id="a"><path d="M.2 0h20v20H.2z" /></clipPath></defs>`
  },
  'svg-vk-circle': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 20,
      h: 20
    },
    symbol: `<g clip-path="url(#a)"><path fill="var(--social-color)" d="M2.874 2.5h14.851v15H2.874z" /><path fill="currentColor" fill-rule="evenodd" d="M1.69 1.406C.3 2.812.3 5.075.3 9.6v.8c0 4.525 0 6.788 1.392 8.194C3.083 20 5.323 20 9.804 20h.792c4.48 0 6.72 0 8.113-1.406C20.1 17.188 20.1 14.925 20.1 10.4v-.8c0-4.525 0-6.788-1.392-8.194C17.317 0 15.076 0 10.596 0h-.792C5.323 0 3.083 0 1.69 1.406Zm1.95 4.677c.107 5.2 2.682 8.325 7.195 8.325h.256v-2.975c1.658.167 2.912 1.392 3.416 2.975h2.343c-.644-2.366-2.335-3.675-3.391-4.175 1.056-.616 2.54-2.116 2.896-4.15h-2.13c-.461 1.65-1.83 3.15-3.134 3.292V6.083H8.96v5.767c-1.32-.333-2.986-1.95-3.06-5.767H3.64Z" clip-rule="evenodd"/></g><defs><clipPath id="a"><path d="M.2 0h20v20H.2z" /></clipPath> </defs>`
  },
  'svg-dzen-circle': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 2000,
      h: 2000
    },
    zoom: 0.01,
    symbol: `<circle id="kNe9" cx="1000" cy="1000" r="1000" fill="currentColor"/><clipPath id="ODPp"><use href="#kNe9"/></clipPath><path d="M970 0s0 600-185 785S0 970 0 970v60s600 0 785 185 185 785 185 785h60s0-600 185-785 785-185 785-185v-60s-600 0-785-185S1030 0 1030 0h-60Z" clip-path="url(#ODPp)" fill="var(--social-color)"/>`
  },
  'svg-chevron': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 20,
      h: 20
    },
    symbol: `<path fill="currentColor" d="M6.697 8.142a.5.5 0 0 0-.707.707l3.686 3.686a.503.503 0 0 0 .454.136.496.496 0 0 0 .253-.136l3.686-3.686a.5.5 0 0 0-.707-.707l-3.333 3.333-3.332-3.333Z"/>`,
    defaultOrientation: 'down'
  },
  'svg-search': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 20,
      h: 20
    },
    symbol: `<path fill="currentColor" fill-rule="evenodd" d="M13.656 15.07a7.562 7.562 0 1 1 1.414-1.414l3.137 3.137a1 1 0 0 1-1.415 1.414l-3.136-3.136ZM3.5 9.063a5.563 5.563 0 1 1 9.5 3.93l-.005.004-.003.004A5.562 5.562 0 0 1 3.5 9.063Z" clip-rule="evenodd"/>`
  },
  'svg-user': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 20,
      h: 20
    },
    symbol: `<path fill="currentColor" fill-rule="evenodd" d="M10 1.5a6 6 0 0 0-3.695 10.727 9.755 9.755 0 0 0-4.75 4.147 1 1 0 1 0 1.732 1A7.753 7.753 0 0 1 10 13.5a7.753 7.753 0 0 1 6.713 3.875 1 1 0 0 0 1.732-1 9.753 9.753 0 0 0-4.75-4.148A6 6 0 0 0 10 1.5Zm-4 6a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" clip-rule="evenodd"/>`
  },
  'svg-sandwich': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 35,
      h: 35
    },
    symbol: `<path fill="#3E4454" d="M0 2a2 2 0 0 1 2-2h31a2 2 0 0 1 2 2v31a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Z"/><path fill="currentColor" d="M9.625 11.667a1 1 0 0 1 1-1h13.75a1 1 0 0 1 0 2h-13.75a1 1 0 0 1-1-1ZM9.625 17.5a1 1 0 0 1 1-1h13.75a1 1 0 1 1 0 2h-13.75a1 1 0 0 1-1-1ZM10.625 22.333a1 1 0 1 0 0 2h13.75a1 1 0 1 0 0-2h-13.75Z"/>`
  },
  'svg-close': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 20,
      h: 20
    },
    symbol: `<path fill="currentColor" d="M14.016 4.793a1 1 0 1 1 1.414 1.414l-4.217 4.217 4.23 4.23a1 1 0 0 1-1.414 1.415l-4.23-4.23-4.092 4.09a1 1 0 0 1-1.414-1.413l4.092-4.092-4.078-4.078A1 1 0 1 1 5.72 4.932L9.799 9.01l4.217-4.217Z"/>`
  },
  'svg-arrow': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 20,
      h: 20
    },
    symbol: `<path fill="currentColor" d="M17.049 12.5a1 1 0 0 0-1.414-1.414L11 15.72V1.45a1 1 0 1 0-2 0v14.27l-4.635-4.635A1 1 0 0 0 2.951 12.5L10 19.549l7.049-7.049Z"/>`
  },
  'svg-arrow-tl': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 20,
      h: 20
    },
    symbol: `<path fill="#currentColor" d="M8.25 3.25a.75.75 0 0 0 0 1.5h6.69L4.22 15.47a.75.75 0 1 0 1.06 1.06L16 5.81v6.69a.75.75 0 0 0 1.5 0V3.25H8.25Z"/>`
  },
  'svg-spinner': <IconDescription>{
    viewBox: {
      x: 0,
      y: 0,
      w: 512,
      h: 512
    },
    symbol: `<path fill="currentColor" d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />`
  }
}

const iconEffects = ['spin'] as const

export const env = {
  texts: {
    requiredError: 'Обязательное поле'
  },
  transliteration: Object.entries(transliteration), // транслитерации
  regexes: {
    // регексы
    azAZ09: /[^a-zA-Z0-9]/g, //поиск всех не [a-zA-Z0-9]
    multispace: /\s+/g, //поиск всех мультипробелов
    notFoundPath: '/:pathMatch(.*)*', // пач для SiteStructurePoint 'страница не найдена'
    emailCheck: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // проверка на e-mail
  },
  links: {
    // ссылки
    tg: 'http://t.me/p5sru',
    vk: 'http://t.me/p5sru',
    dzen: 'http://t.me/p5sru'
  },
  phones: {
    // телефоны
    main: '8 (800) 555-15-07'
  },
  dimensions: {
    // отступы
    secondaryTopMenu: {
      firstBeforeMore: 420
    }
  },
  api: {
    // апи
    baseURL: 'https://api.p5s.ru/p5s',
    httpMethods: ['GET', 'POST', 'DELETE', 'PATH', 'PUT', 'HEAD'],
    forms: {
      keys: ['feedbackForm'],
      selectorOptions: {
        feedbackMain: [
          { value: 'type1', text: 'тип 1' },
          { value: 'type2', text: 'тип 2' },
          { value: 'type3', text: 'тип 3' },
          { value: 'type4', text: 'тип 4' },
          { value: 'type5', text: 'тип 5' }
        ],
        requestForCooperation: [
          { value: 'tarif1', text: 'тариф 1' },
          { value: 'tarif2', text: 'тариф 2' },
          { value: 'tarif3', text: 'тариф 3' },
          { value: 'tarif4', text: 'тариф 4' },
          { value: 'tarif5', text: 'тариф 5' }
        ]
      }
    }
  },
  icons,
  iconEffects
} as const
