import { siteRouter } from '@/router'
import { ref, type Ref } from 'vue'
import type { SiteFolderKeys, SiteItemKeys } from '../types'

export class Menu {
  horizontalMainViewLenght: Ref<number>
  rightMainXs: number[] = []
  moreMainWidth: number = 0
  initialPadding: number = 0
  openMainMenu: Ref<SiteFolderKeys | null> = ref(null)
  openMainMenuMore = ref(false)
  openSecondaryMenu = ref(false)
  mobileMenuView = ref(false)

  secondMenuWidths: number[] = []

  constructor() {
    this.horizontalMainViewLenght = ref(Object.keys(siteRouter.activeMenuFooterItemEntries).length)
  }

  /** инициализация верхнего меню
   *
   * @param topMenu
   */
  initTopMenu() {
    this.horizontalViewIndexProcessor('top')
  }

  initSecondMenu() {
    this.horizontalViewIndexProcessor('second')
  }

  async destroy() {}

  horizontalViewIndexProcessor(only?: 'top' | 'second') {
    if (!only || only === 'top') this.horizontalViewIndexProcessorTop()
    if (!only || only === 'second') this.horizontalViewIndexProcessorSecond()
  }
  /**
   * генерация верхнего меню
   */
  private horizontalViewIndexProcessorTop() {
    const inline: HTMLElement = document.querySelector('#TopMenu ._inline')!
    const block: HTMLElement = document.querySelector('#TopMenu ._block ._groups')!
    const inlineItems = [...inline.childNodes].filter((i) => i instanceof HTMLElement)
    block.prepend(...inlineItems)
    const blockItems = [...block.childNodes].filter((i) => i instanceof HTMLElement)
    const inlineItemTop = document
      .querySelector('#TopMenu ._block ._label')
      ?.getBoundingClientRect().top
    for (let i = 0; i < 5; i++) {
      inline.append(blockItems[i])
      const addedTop = blockItems[i].getBoundingClientRect().top
      if (inlineItemTop !== addedTop) {
        block.prepend(blockItems[i])
        break
      }
    }
  }
  /**
   * генерация вторичного меню
   */
  private horizontalViewIndexProcessorSecond() {
    const inline: HTMLElement = document.querySelector('#SecondaryMenu ._first ._inline')!
    const block: HTMLElement = document.querySelector('#SecondaryMenu ._first ._block ._items')!
    const inlineItems = [...inline.childNodes].filter((i) => i instanceof HTMLElement)
    block.prepend(...inlineItems)
    const blockItems = [...block.childNodes].filter((i) => i instanceof HTMLElement)
    const inlineItemTop = document
      .querySelector('#SecondaryMenu ._first ._block ._label')
      ?.getBoundingClientRect().top
    for (let i = 0; i < 4; i++) {
      inline.append(blockItems[i])
      const addedTop = blockItems[i].getBoundingClientRect().top
      if (inlineItemTop !== addedTop) {
        block.prepend(blockItems[i])
        break
      }
    }
  }

  menuClick(key: SiteFolderKeys) {
    this.openMainMenu.value = key === this.openMainMenu.value ? null : key
  }

  menuMoreClick() {
    this.openMainMenu.value = null
    this.openMainMenuMore.value = !this.openMainMenuMore.value
  }

  menuItemClick(event: Event) {
    const intoMore = !!(event.target as HTMLElement).closest('._block')
    if (intoMore) this.openMainMenuMore.value = false
    else this.openMainMenu.value = null
  }

  private _waitTimerSec: any
  menuSecondaryClick(key?: SiteItemKeys, wait?: number) {
    clearTimeout(this._waitTimerSec)
    if (key) {
      if (wait) {
        this._waitTimerSec = setTimeout(() => (this.openSecondaryMenu.value = false), wait)
      } else {
        this.openSecondaryMenu.value = false
      }
    } else {
      this.openSecondaryMenu.value = !this.openSecondaryMenu.value
    }
  }
}
