<script setup lang="ts">
import HorizontalSliderComponent from '@/components/widgets/SliderComponent.vue'
import NoveltyItemComponent from '@/components/widgets/NoveltyItemComponent.vue'
import type { NoveltieItem } from '@/types'
const items: NoveltieItem[] = [
  {
    image: '1.webp',
    price: 3660,
    name: 'Клиторальный стимулятор ENIGMA Deep Rose',
    brand: 'LELO',
    new: true,
    discount: true
  },
  {
    image: '1.webp',
    price: 3660,
    name: 'Стильный стимулятор для точки G Penguin',
    brand: 'Satisfyer',
    new: false,
    discount: false
  },
  {
    image: '1.webp',
    price: 1320,
    name: 'Двойной вибратор с фиксацией Nova',
    brand: 'We-Vibe',
    new: false,
    discount: true
  },
  {
    image: '1.webp',
    price: 3660,
    name: 'Пеньюар Amanta Babydoll & Matching Thong',
    brand: 'Obsessive',
    new: true,
    discount: false
  },
  {
    image: '1.webp',
    price: 3660,
    name: 'Клиторальный стимулятор ENIGMA Deep Rose',
    brand: 'LELO',
    new: true,
    discount: true
  },
  {
    image: '1.webp',
    price: 3660,
    name: 'Стильный стимулятор для точки G Penguin',
    brand: 'Satisfyer',
    new: false,
    discount: false
  },
  {
    image: '1.webp',
    price: 1320,
    name: 'Двойной вибратор с фиксацией Nova',
    brand: 'We-Vibe',
    new: false,
    discount: false
  },
  {
    image: '1.webp',
    price: 3660,
    name: 'Пеньюар Amanta Babydoll & Matching Thong',
    brand: 'Obsessive',
    new: false,
    discount: false
  }
]
</script>
<template>
  <div class="Novelties">
    <div class="horizontal-limiter">
      <HorizontalSliderComponent name="Новинки">
        <NoveltyItemComponent :key="k" v-for="[k, item] of items.entries()" v-bind="item" />
      </HorizontalSliderComponent>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.Novelties {
  background-color: $bg-light;
  padding-top: clamp(30px, calc(100vw / 1400 * 60), 60px);
  padding-bottom: clamp(30px, calc(100vw / 1400 * 60), 60px);
}
</style>
