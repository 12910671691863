<script setup lang="ts">
import HorizontalSliderComponent from '@/components/widgets/SliderComponent.vue'
const items: string[] = [
  '1.png',
  '1.png',
  '1.png',
  '1.png',
  '1.png',
  '1.png',
  '1.png',
  '1.png',
  '1.png',
  '1.png',
  '1.png',
  '1.png',
  '1.png',
  '1.png'
]
</script>
<template>
  <div class="Brands">
    <div class="horizontal-limiter">
      <HorizontalSliderComponent name="Популярные бренды" :gap-desk="40" :gap-mob="15">
        <div class="_brand" :key="k" v-for="[k, item] of items.entries()">
          <img :src="`/img/brands/160/${item}`" />
        </div>
      </HorizontalSliderComponent>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.Brands {
  padding-top: clamp(30px, calc(100vw / 1400 * 60), 60px);
  padding-bottom: clamp(30px, calc(100vw / 1400 * 60), 60px);
  ._brand {
    width: 108px;
    height: 114px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
