<script setup lang="ts">
import TopBlockComponent from '@/components/pages/home/TopBlockComponent.vue'
import ForSellersComponent from '@/components/pages/home/ForSellersComponent.vue'
import FeedbackComponent from '@/components/pages/home/FeedbackComponent.vue'
import ForSuppliersComponent from '@/components/pages/home/ForSuppliersComponent.vue'
import WhatWeSellComponent from '@/components/pages/home/WhatWeSellComponent.vue'
import NoveltiesComponent from '@/components/pages/home/NoveltiesComponent.vue'
import BrandsComponent from '@/components/pages/home/BrandsComponent.vue'
import NewsComponent from '@/components/pages/home/NewsComponent.vue'
import AboutUsComponent from '@/components/pages/home/AboutUsComponent.vue'
import AboutCompanyComponent from '@/components/pages/home/AboutCompanyComponent.vue'
import { onMounted, ref, watch, type Ref } from 'vue'
import { useStore } from '@/store/useStory'
const store = useStore()

let viewport = store.viewport.value

watch(store.viewport, (val) => {
  viewport = val
  feedbackRender()
})

function feedbackRender() {
  const fb = document.getElementById('FeedbackMain')
  if (!fb) return
  if (viewport.w > 480) {
    if (fb.classList.contains('_mob')) {
      fb.classList.remove('_mob')
      fb.classList.add('_desk')
      fb.style.order = '30'
    }
  } else {
    if (fb.classList.contains('_desk')) {
      fb.classList.remove('_desk')
      fb.classList.add('_mob')
      fb.style.order = '65'
    }
  }
}
onMounted(() => {
  feedbackRender()
})
</script>

<template>
  <div class="Home">
    <TopBlockComponent style="order: 10" />
    <ForSellersComponent style="order: 20" />
    <FeedbackComponent style="order: 30" class="_desk" />
    <ForSuppliersComponent style="order: 40" />
    <WhatWeSellComponent style="order: 50" />
    <NoveltiesComponent style="order: 60" />
    <BrandsComponent style="order: 70" />
    <NewsComponent style="order: 80" />
    <AboutUsComponent style="order: 90" />
    <AboutCompanyComponent style="order: 100" />
  </div>
</template>

<style scoped lang="scss">
.Home {
  display: flex;
  flex-direction: column;
  :deep(#FeedbackMain._mob) {
    background-color: $bg-auto;
    input,
    textarea {
      background-color: $bg-light-blue;
    }
  }
}
</style>
