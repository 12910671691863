<script setup lang="ts">
import type { InputFieldBind } from '@/types/forms'

const props = withDefaults(
  defineProps<InputFieldBind & { modelValue: string | number | undefined }>(),
  {
    type: 'text',
    required: false
  }
)
const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void
}>()
const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement
  emit('update:modelValue', target.value)
}

const required = props.required === 'false' ? false : !!props.required
</script>
<template>
  <div class="Input">
    <label :class="{ error: props.error }"
      ><span v-html="props.label" /><span class="_req" v-if="required">*</span></label
    >
    <textarea
      :class="{ error: props.error }"
      v-if="props.type === 'textarea'"
      :value="props.modelValue"
      @input="onInput"
      :placeholder="placeholder"
      :required="required"
    />
    <input
      v-else
      :class="{ error: props.error }"
      :type="props.type"
      :value="props.modelValue"
      @input="onInput"
      :placeholder="placeholder"
      :required="required"
    />
    <div v-if="props.error" :class="{ error: props.error }" v-html="props.error" />
  </div>
</template>
<style lang="scss" scoped>
.Input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  label {
    display: block;
    line-height: 12px;
    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      &._req {
        color: $error;
        margin-left: 2px;
      }
    }
  }
  div.error {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
  input,
  textarea {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    height: 40px;
    padding: 0 10px;
    border: none;
    &::placeholder {
      color: $text-disabled;
    }
  }
  textarea {
    min-height: 84px;
    padding: 10px;
    resize: none;
    overflow: auto;
  }
}
</style>
