<script setup lang="ts">
import ButtonComponent from '@/components/elements/ButtonComponent.vue'
import SiteItemComponent from '@/components/elements/SiteItemComponent.vue'
import { SiteForm } from '@/lib/siteForm'
import type { SiteFormsKey } from '@/types'
import { ref } from 'vue'

type Props = {
  formName: SiteFormsKey
  localStore?: true
}

const { formName, localStore } = defineProps<Props>()

const form = new SiteForm(formName, localStore)

function closeSent() {
  form.clear()
  form.status.value = 'new'
}
function closeError() {
  form.status.value = 'new'
}
const dataConsent = ref(!form.item.dataConsent)
</script>
<template>
  <div id="Form5" class="Feedback block" :class="{ [`__${form.formKey}`]: true }">
    <h2 v-html="form.item.head1" />
    <h5 v-if="form.item.head2" v-html="form.item.head2" />
    <form @submit.prevent="form.send" novalidate>
      <div class="_fields" :key="form.tries.value">
        <component
          :is="field.component"
          :key="k"
          v-for="[k, field] in Object.entries(form.item.form)"
          v-bind="field.bind"
          v-model="(form.data.value as any)[k]"
          :class="`_field-${k} _type-${field.bind.type ?? 'text'}`"
        />
      </div>

      <div v-if="form.item.dataConsent" class="_consent">
        <input type="checkbox" v-model="dataConsent" /><label>
          Я даю согласие на
          <SiteItemComponent
            site-key="согласие на обработку персональных данных"
            alias="обработку персональных данных"
            style="text-transform: lowercase"
        /></label>
      </div>
      <div class="_buttons">
        <ButtonComponent
          theme="five"
          class="page"
          size="medium"
          type="submit"
          :text="form.item.sendButtonText"
          :disabled="form.status.value !== 'new' || (form.item.dataConsent && !dataConsent)"
          :icon="{ iconId: 'svg-arrow-tl', embedded: { position: 'right' } }"
          :async-wait="dataConsent"
        />
        <!-- <ButtonComponent
          v-if="form.clearable.value"
          theme="two"
          text="Очистить"
          @on-click="form.clear()"
        /> -->
      </div>
      <div v-if="form.status.value === 'sent'" class="_sent" @click="closeSent">
        <div>
          <div>
            Форма успешно отправлена
            <ButtonComponent theme="icon" text="закрыть" :icon="{ iconId: 'svg-close' }" />
          </div>
          <div v-html="form.serverMessage" />
        </div>
      </div>
      <div v-if="form.status.value === 'error'" class="_error" @click="closeError">
        <div>
          <div>
            Ошибка отправки формы
            <ButtonComponent theme="icon" text="закрыть" :icon="{ iconId: 'svg-close' }" />
          </div>
          <div v-html="form.serverMessage" />
        </div>
      </div>
    </form>
  </div>
</template>
<style lang="scss" scoped>
.Feedback {
  display: flex;
  flex-direction: column;
  gap: 20px;
  h2 {
    color: $text-link;
  }
  &.__form1v1 {
    h2 {
      color: $text-primary;
    }
  }
  &.__form1v1,
  &.__form3v1 {
    @media (min-width: 600px) {
      ._field-email,
      ._field-phone {
        flex-basis: calc(50% - 15px);
      }
    }
  }
  &.__form2v1,
  &.__form4v1,
  &.__form5v1 {
    @media (min-width: 600px) {
      ._field-email,
      ._field-type,
      ._field-name,
      ._field-phone {
        flex-basis: calc(50% - 15px);
      }
    }
  }
  form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    ._fields {
      display: flex;
      flex-wrap: wrap;
      gap: 20px 30px;
      > * {
        flex-basis: 100%;
      }

      @media (max-width: 600px) {
        gap: 15px;
      }
    }
    ._consent {
      display: flex;
      align-items: start;
      gap: 10px;
      font-size: 15px;
      line-height: 15px;
      input {
        position: relative;
        top: 1px;
      }
    }
    ._buttons {
      button {
        height: 40px;
      }
      display: flex;
      justify-content: space-between;
      @media (max-width: 359px) {
        button {
          font-size: 11px;
        }
      }
      @media (max-width: 300px) {
        flex-direction: column;
        gap: 10px;
      }
    }

    ._sent,
    ._error {
      position: absolute;
      width: 104%;
      height: 104%;
      top: -2%;
      left: -2%;
      border-radius: 2%;
      background-color: $bg-dark;
      opacity: 0.9;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 700;
      color: $text-link;
      @media (max-width: 600px) {
        align-items: end;
      }
    }
    ._error {
      color: $error;
    }
  }
  @media (min-width: 601px) {
    // h2 {
    //   line-height: 80%;
    // }
  }
  @media (max-width: 480px) {
    &.__form2v1,
    &.__form3v1,
    &.__form4v1,
    &.__form5v1 {
      position: relative;
      left: -15px;
      width: 100vw;
      border-radius: 0;
      padding: 30px 15px;
    }
  }
}
</style>
