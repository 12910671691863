<script setup lang="ts">
import ButtonComponent from '../elements/ButtonComponent.vue'
import IconComponent from '../icons/IconComponent.vue'
import LogoComponent from '../widgets/LogoComponent.vue'
import { siteRouter } from '@/router'
import type { MenuStructure, SiteFolderKeys, SiteItemKeys } from '@/types'
import { strCap } from '@/lib/utils'
import SiteItem from '../elements/SiteItemComponent.vue'
import { useStore } from '@/store/useStory'

const store = useStore()
const activeMenuItemEntries = Object.entries(siteRouter.activeMenuFooterItemEntries)
function folderClick(element: MouseEvent) {
  const groupElement = (element.target! as Element).closest('._group')!
  if (groupElement.classList.contains('_closed')) groupElement.classList.remove('_closed')
  else groupElement.classList.add('_closed')
}
</script>
<template>
  <section class="MobileMenu" :class="{ _view: store.Menu.mobileMenuView.value }">
    <div class="_header">
      <div class="_logo-holder" @click="store.Menu.mobileMenuView.value = false">
        <LogoComponent class="_logo-limiter" />
      </div>
      <ButtonComponent
        class="_close"
        theme="icon"
        :icon="{ iconId: 'svg-close', iconColor: 'var(--text-primary)' }"
        text="Закрыть"
        @click="store.Menu.mobileMenuView.value = false"
      />
    </div>
    <div class="_menu">
      <div
        class="_group _closed"
        :key="groupName"
        v-for="[groupName, groupItems] of activeMenuItemEntries as [
          SiteFolderKeys,
          MenuStructure
        ][]"
      >
        <div class="_folder" v-on:click="folderClick">
          <span v-html="strCap(groupName)" />
          <IconComponent icon-id="svg-chevron" :icon-width="24" class="_chevron" />
        </div>
        <div class="_items">
          <div
            :key="item"
            v-for="item of Object.keys(groupItems) as SiteItemKeys[]"
            v-on:click="null"
          >
            <SiteItem
              class="_item-link"
              :site-key="item"
              v-on:click="store.Menu.mobileMenuView.value = false"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.MobileMenu {
  width: 100vw;
  height: 100vh;
  background-color: $bg-auto;
  position: fixed;
  top: -110vh;
  z-index: 999;
  overflow: scroll;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  ._logo-limiter {
    width: 140px;
  }
}
@media (max-width: 480px) {
  .MobileMenu {
    &._view {
      top: 0;
    }
    ._header {
      width: 100%;
      height: 65px;
      background-color: $bg-light;
      padding: 10px 15px;
      display: flex;
      ._logo-holder {
        flex-shrink: 0;
        flex-grow: 1;
        padding-top: 5px;
        ._logo {
          margin-right: 6.6px;
        }
      }
      ._close {
        flex-shrink: 0;
        color: $text-primary;
        margin-right: 1px;
      }
    }
    ._menu {
      padding: 10px 15px;
      ._group {
        min-height: 50px;
        border-bottom: 1px solid #eff0f3;
        ._folder {
          display: flex;
          padding: 15px 0;
          cursor: pointer;
          ._chevron {
            color: $text-primary;
          }
          span {
            flex-shrink: 0;
            flex-grow: 1;
            max-width: calc(100vw - 54px);
            display: inline-block;
            color: $text-primary;
            font-weight: 500;
          }
          svg {
            flex-shrink: 0;
            transform: rotate(180deg);
          }
        }
        ._items {
          ._item-link {
            color: $text-secondary;
            padding: 15px;
            display: inline-block;
            &.router-link-active {
              text-decoration: underline;
            }
          }
        }
        &._closed {
          ._items {
            display: none;
          }
          ._folder {
            svg {
              transform: none;
            }
          }
        }
      }
    }
  }
}
</style>
