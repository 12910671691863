<script setup lang="ts">
import type { SelectFieldBind } from '@/types/forms'

const props = withDefaults(defineProps<SelectFieldBind & { modelValue: string | undefined }>(), {
  required: false
})
const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void
}>()

const required = props.required === 'false' ? false : !!props.required

const onChange = (event: Event) => {
  const target = event.target as HTMLSelectElement
  emit('update:modelValue', target.value) // Отправляем новое значение при изменении
}
</script>
<template>
  <div class="Select">
    <label :class="{ error: props.error }"
      ><span v-html="props.label" /><span class="_req" v-if="required">*</span></label
    >
    <div class="_select">
      <select
        :value="props.modelValue"
        @change="onChange"
        :required="required"
        :class="{ _notSelected: !props.modelValue }"
      >
        <option
          v-if="props.placeholder"
          selected
          disabled
          hidden
          value=""
          v-html="props.placeholder"
        />
        <option
          :key="k"
          v-for="[k, text] of Object.entries(props.options)"
          v-html="text"
          :value="k"
        />
      </select>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <use href="#svg-chevron" />
      </svg>
    </div>
    <div v-if="props.error" :class="{ error: props.error }" v-html="props.error" />
  </div>
</template>
<style lang="scss" scoped>
.Select {
  display: flex;
  flex-direction: column;
  gap: 10px;
  label {
    display: block;
    line-height: 12px;
    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      &._req {
        color: $error;
        margin-left: 2px;
      }
    }
  }
  div.error {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
  div._select {
    height: 40px;
    position: relative;
    svg {
      width: 26px;
      height: 26px;
      color: $icon-secondary;
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    border: none;
    width: 100%;
    height: 100%;
    &._notSelected {
      color: $text-secondary;
    }
  }
}
</style>
