<script setup lang="ts">
import { secondaryTopMenu } from '@/router/siteStructure'
import SiteItemComponent from '../elements/SiteItemComponent.vue'
import type { SitePointKeys } from '@/types'
import IconComponent from '../icons/IconComponent.vue'
import { onMounted, onUnmounted, watch } from 'vue'
import { useStore } from '@/store/useStory'

const store = useStore()
const onClick = (event: Event) => {
  if (store.Menu.openSecondaryMenu.value) {
    const openMenu = document.querySelector('.SecondaryMenu ._block')
    if (openMenu !== event.target && !openMenu?.contains(event.target as Node))
      store.Menu.openSecondaryMenu.value = false
  }
}

onMounted(() => {
  window.addEventListener('click', onClick)
  store.Menu.initSecondMenu()
})

onUnmounted(() => {
  window.removeEventListener('click', onClick)
})
</script>
<template>
  <div class="SecondaryMenu" id="SecondaryMenu">
    <div class="horizontal-limiter">
      <div class="_content">
        <div class="_first">
          <div class="_inline"></div>
          <div
            class="_block"
            :class="{ _open: store.Menu.openSecondaryMenu.value }"
            v-on:click="store.Menu.menuSecondaryClick()"
          >
            <div class="_label">
              <span>Ещё</span>
              <IconComponent
                style="width: 20px; height: 20px"
                icon-id="svg-chevron"
                :icon-rotate="store.Menu.openSecondaryMenu.value ? 'top' : 'down'"
              />
            </div>
            <div class="_items">
              <SiteItemComponent
                class="_link"
                v-on:click="store.Menu.menuSecondaryClick(key, 1000)"
                :key="key"
                v-for="[key, alias] of Object.entries(secondaryTopMenu.first).filter(
                  ([, v]) => v
                ) as [SitePointKeys, string | boolean][]"
                :site-key="key"
                :alias="typeof alias === 'string' ? alias : undefined"
              />
            </div>
          </div>
        </div>
        <div class="_second">
          <SiteItemComponent
            class="_link"
            :key="key"
            v-for="[key, alias] of Object.entries(secondaryTopMenu.second).filter(([, v]) => v) as [
              SitePointKeys,
              string | boolean
            ][]"
            :site-key="key"
            :alias="typeof alias === 'string' ? alias : undefined"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.SecondaryMenu {
  margin-bottom: 15px;
  ._content {
    height: 20px;
    max-height: 20px;
    font-size: 14px;
    display: flex;
    gap: 30px;
    ._link {
      color: $text-primary;
      font-style: normal;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      &:hover {
        color: $text-link;
      }
    }

    ._second {
      flex-basis: 50%;
      display: flex;
      gap: clamp(20px, 2.2vw, 31.5px);
      flex-wrap: wrap;
      justify-content: end;
      height: 100%;
      overflow: hidden;
    }
    ._first {
      flex-basis: 50%;
      display: flex;
      gap: clamp(20px, 2.2vw, 31.5px);
      height: 100%;
      ._inline {
        display: inline-flex;
        flex-wrap: wrap;
        gap: clamp(20px, 2.2vw, 31.5px);
        overflow: hidden;
      }
      ._block {
        font-weight: 500;
        display: inline-flex;
        flex-direction: column;
        ._label {
          color: $text-primary;
          &:hover {
            color: $text-link;
          }
          width: 60px;
          cursor: pointer;
          svg {
            margin: -5px 0;
          }
        }
        ._items {
          position: absolute;
          display: none;
          flex-direction: column;
          margin: 20px 0 0 -20px;
          padding: 20px 20px 20px 20px;
          background-color: $bg-auto;
          border-radius: 0 0 4px 4px;
          z-index: 1;
        }
        &._open ._items {
          display: flex;
          gap: 20px;
        }
      }
    }

    @-moz-document url-prefix() {
      .second {
        scrollbar-width: none;
      }
    }

    @media (max-width: 600px) {
      display: block;
      ._first {
        display: none;
      }
      ._second {
        display: block;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        > * {
          display: inline-block;
          white-space: normal;
          margin-right: 15px;
        }
      }
    }
  }
  :deep(a.router-link-active span) {
    font-weight: 700 !important;
  }
}
</style>
