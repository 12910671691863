import type { CSSProperties } from 'vue'
import type { IconDescription, IconProps } from '../types'
import type { env } from './env'
import type { Store } from './store'
import { strCap } from '@/lib/utils'

/**
 * Класс для управления иконками
 */
export class IconProcessor {
  icons: typeof env.icons
  mapDefs: string = ''
  orientationValues = {
    down: 270,
    top: 90,
    left: 180,
    right: 0
  }

  /**
   * Конструктор класса IconProcessor
   *
   * @param iconStr
   */
  constructor(store: Store) {
    this.icons = store.env.icons
  }

  async init() {
    for (const [id, desc] of Object.entries(this.icons)) {
      if (desc.symbol === false) continue
      this.mapDefs += `<symbol id="${id}" viewBox="${desc.viewBox.x} ${desc.viewBox.y} ${desc.viewBox.w} ${desc.viewBox.h}" fill="currentColor">${desc.symbol}</symbol>`
    }
  }

  async destroy() {}

  svg(props: IconProps): {
    id: string
    style?: CSSProperties
    effects?: string[]
    icon: string
    viewbox: string
  } {
    const iconD = this.icons[props.iconId]

    let width = props.iconWidth
    let height = props.iconHeight

    if (!props.noCalculate) {
      if (width === undefined && height === undefined) {
        width = iconD.viewBox.w * (iconD.zoom ?? 1)
        height = iconD.viewBox.h * (iconD.zoom ?? 1)
      } else if (width !== undefined && height !== undefined) {
        /** уже заданы */
      } else if (width !== undefined) {
        height = (iconD.viewBox.h * width) / iconD.viewBox.w
      } else if (height !== undefined) {
        width = (iconD.viewBox.w * height) / iconD.viewBox.h
      }
    }

    const rotate = this.computeTransformRotate(props.iconRotate, iconD.defaultOrientation)
    const style: CSSProperties = {
      width: width ? `${width}px` : undefined,
      height: height ? `${height}px` : undefined,
      transform: rotate ? `rotate(${rotate}deg)` : undefined
    }

    const effects = (
      typeof props.iconEffects === 'string' ? [props.iconEffects] : props.iconEffects
    )?.map((i) => `effect${strCap(i)}`)

    let icon = this.icons[props.iconId].symbol
    if (!icon) icon = document.getElementById(props.iconId)?.innerHTML ?? ''
    if (props.transform) {
      for (const [k, v] of Object.entries(props.transform.replacer)) {
        icon = icon.replace(new RegExp(k, 'g'), v)
      }
    }

    const viewbox = `${this.icons[props.iconId].viewBox.x} ${this.icons[props.iconId].viewBox.y} ${this.icons[props.iconId].viewBox.w} ${this.icons[props.iconId].viewBox.h}`

    return {
      style,
      id: `#${props.iconId}`,
      effects,
      icon,
      viewbox
    }
  }

  /**
   * Поворот иконок
   *
   * @param orientation
   * @param defaultOrientation
   * @returns
   */
  computeTransformRotate(
    orientation?: IconDescription['defaultOrientation'] | number,
    defaultOrientation?: IconDescription['defaultOrientation']
  ): number | undefined {
    defaultOrientation ??= 'top'
    if (!orientation) return undefined
    if (typeof orientation === 'number') return orientation
    return this.orientationValues[defaultOrientation] - this.orientationValues[orientation]
  }
}
