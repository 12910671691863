<script setup lang="ts">
import { useStore } from '@/store/useStory'
import type { IconProps } from '@/types'
import { watch, ref } from 'vue'

const store = useStore()
const props = defineProps<IconProps>()
const svg = ref(store.icon.svg(props))

watch(
  () => props,
  (newProps) => {
    svg.value = store.icon.svg(newProps)
  },
  { deep: true }
)

// :class="svg.effects ? svg.effects.join(' ') : undefined"

// `<use href='${svg.id}' />` | svg.icon
</script>

<template>
  <svg
    :class="svg.effects ? svg.effects.join(' ') : undefined"
    :style="svg.style"
    xmlns="http://www.w3.org/2000/svg"
    v-html="`<use href='${svg.id}' />`"
    :viewBox="svg.viewbox"
  />
</template>
