<script setup lang="ts">
import type { IconProps, SiteItemKeys } from '@/types'
import IconComponent from '../icons/IconComponent.vue'
import { siteItems } from '@/router/siteStructure'
import { useRouter } from 'vue-router'

interface Props {
  theme?: 'one' | 'two' | 'three' | 'four' | 'five' | 'icon'
  size?: 'large' | 'medium' | 'small'
  icon?: IconProps
  disabled?: boolean
  text: string
  type?: 'reset' | 'submit' | 'button'
  to?: `#${string}` | `http${string}` | SiteItemKeys
  asyncWait?: boolean
  mobileIconization?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  type: 'button',
  theme: 'one',
  size: 'large'
})

const router = useRouter()
const emit = defineEmits(['on-click'])

const handleClick = () => {
  if (props.to) {
    if (props.to.charAt(0) === '#') {
      const targetElement = document.querySelector(props.to)
      if (targetElement) targetElement.scrollIntoView({ behavior: 'smooth' })
    } else if (props.to.startsWith('http')) {
      window.location.href = props.to
    } else {
      const item = siteItems[props.to as SiteItemKeys]
      const path = typeof item === 'object' ? (item.path ?? null) : null
      if (path) router.push(path)
    }
  }
  emit('on-click')
}
</script>
<template>
  <button
    @click="handleClick"
    :type="props.type"
    :disabled="disabled"
    :class="`_${props.theme} _${props.size}${props.mobileIconization ? ' _mobile-iconized' : ''}`"
  >
    <div :style="{ opacity: props.asyncWait && props.disabled ? 0.25 : 1 }">
      <IconComponent v-if="props.icon && props.theme === 'icon'" v-bind="props.icon" />
      <template v-else>
        <IconComponent
          v-if="props.icon && props.icon.embedded?.position !== 'right'"
          v-bind="props.icon"
        />
        <span v-html="props.text" />
        <IconComponent
          v-if="props.icon && props.icon.embedded?.position === 'right'"
          v-bind="props.icon"
        />
      </template>
    </div>
    <IconComponent
      v-if="props.asyncWait && props.disabled"
      icon-id="svg-spinner"
      :icon-width="24"
      icon-effects="spin"
    />
  </button>
</template>
<style lang="scss" scoped>
button {
  border: none;
  border-radius: 2px;
  color: $text-contrast-inverse;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  position: relative;

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    height: 100%;
  }

  &._large {
    height: 40px;
    padding: 0 15px;
  }

  &._medium {
    height: 35px;
    padding: 0 15px;
  }

  &._small {
    height: 25px;
    padding: 0 10px;
    font-weight: 400;
  }

  &._icon {
    padding: 0;
    background: transparent;
  }
  &._icon:hover {
    svg {
      color: $icon-hover;
    }
  }
  &._one {
    background: $base-color;
    &:hover {
      background: $button-accent-bg;
    }
  }
  &._two {
    background: $button-second-bg;
    &:hover {
      background: $base-color;
    }
  }
  &._four {
    background-color: transparent;
    border: solid 1px $button-four-color;
    color: $button-four-color;
    &:hover {
      color: $button-four-hovered;
      border-color: $button-four-hovered;
    }
  }
  &._five {
    border-radius: 4px;
    background: $button-accent-bg;
    &:hover {
      background: $base-color;
    }
    &._large {
      padding: 0 30px;
      height: 50px;
    }
    &._medium {
      height: 40px;
    }
    &._small {
      height: 35px;
    }
  }

  &:disabled {
    cursor: inherit;
    color: $text-disabled;
    background: $bg-light;
    &:hover {
      color: $text-disabled;
      background: $bg-light;
    }
  }
}
</style>
