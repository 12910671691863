import type { ApiKey, ApiMethod } from '../types'
import type { Store } from './store'

export class SiteApi {
  store: Store
  points: Record<ApiKey, ApiMethod>

  constructor(store: Store, points: Record<ApiKey, ApiMethod>) {
    this.store = store
    this.points = points
  }

  async init() {}

  async destroy() {}

  async request(key: ApiKey, data: any) {
    const apiPoint = this.points[key]
    const url = `${this.store.env.api.baseURL}${apiPoint.path}${apiPoint.queryFormula ? '?' + apiPoint.queryFormula(data) : ''}`
    const requestInit: RequestInit = {
      method: apiPoint.method,
      headers: {
        'Content-Type':
          apiPoint.method === 'GET' ? 'application/json' : 'application/x-www-form-urlencoded'
      }
    }
    if (apiPoint.method === 'POST' && apiPoint.bodyFormula)
      requestInit.body = apiPoint.bodyFormula(data)
    try {
      const response = await fetch(url, requestInit)
      return apiPoint.responseHandler(response)
    } catch (error: any) {
      if (error.name === 'TypeError') throw new Error('Сервер недоступен. Попробуйте позже.')
      else throw new Error(error.message || 'Произошла ошибка')
    }
  }
}
