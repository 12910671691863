<script setup lang="ts">
import type { IconProps, SiteItemKeys } from '@/types'
import { strCap } from '@/lib/utils'
import { siteItems } from '@/router/siteStructure'
import IconComponent from '../icons/IconComponent.vue'

interface Props {
  siteKey: SiteItemKeys
  noLink?: boolean
  icon?: IconProps
  alias?: string
}
const { siteKey, noLink, icon, alias } = defineProps<Props>()
const item = siteItems[siteKey]
const alias_ = alias ?? (typeof item === 'object' ? (item.alias ?? siteKey) : siteKey)
const path = typeof item === 'object' ? (item.path ?? null) : null
</script>
<template>
  <RouterLink v-if="path && !noLink" :to="path">
    <slot v-if="$slots.default" />
    <IconComponent v-else-if="icon" v-bind="icon" />
    <span v-else v-html="strCap(alias_)" />
  </RouterLink>
  <slot v-else-if="$slots.default" />
  <span v-else class="_no-link" v-html="strCap(alias_)" />
</template>
