import { IconProcessor } from '@/store/iconProcessor'
import { SiteApi } from '@/store/siteApi'
import { Menu } from '@/store/topMenu'
import { env } from './env'
import { apiPoints } from './siteApiPoints'
import { ref } from 'vue'

export class Store {
  icon: IconProcessor
  api: SiteApi
  env: typeof env

  viewport = ref({
    h: 0,
    w: 0
  })
  scrollY = ref(0)

  Menu: Menu

  constructor() {
    this.env = env
    this.icon = new IconProcessor(this)
    this.api = new SiteApi(this, apiPoints)
    this.Menu = new Menu()
    this.viewport.value = {
      h: window.innerHeight,
      w: window.innerWidth
    }
  }

  async init() {
    window.addEventListener('resize', this.onResize.bind(this))
    window.addEventListener('scroll', this.onScroll.bind(this))
    await this.icon.init()
    await this.api.init()
  }

  async destroy() {
    window.removeEventListener('resize', this.onResize.bind(this))
    await this.Menu.destroy()
    await this.api.destroy()
    await this.icon.destroy()
  }

  onResize(ev: UIEvent) {
    this.viewport.value = {
      h: window.innerHeight,
      w: window.innerWidth
    }
  }

  onScroll(ev: Event) {
    this.scrollY.value = window.scrollY
  }
}
