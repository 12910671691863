import type { ApiKey, ApiMethod, FeedbackForm, FormData } from '@/types'

export const apiPoints: Record<ApiKey, ApiMethod> = {
  feedbackForm: {
    path: '/feedback/sendFeedback/',
    method: 'POST',
    responseHandler: async (response) => {
      try {
        const responseData = await response.json()
        if (responseData.status === 0) throw new Error(responseData.message)
        else return responseData.message
      } catch {
        throw new Error('Ошибка обработки ответа')
      }
    },
    bodyFormula: (data: FormData<FeedbackForm> & { head1: string }) =>
      new URLSearchParams({
        type: 'contacts',
        content: data.message ?? '-',
        'fields[почта]': data.email ?? '-',
        'fields[фио]': data.name ?? '-',
        subject: `${data.head1}: ${data.type ?? '-'}`
      })
  }
}
