import { ref, type Ref, watch } from 'vue'
import type { FormData, FormFieldBind, SiteFormsKey } from '../types'
import { lsKey, sleep } from './utils'
import { siteForm } from '@/router/siteStructure'
import { env } from '@/store/env'
import { useStore } from '@/store/useStory'
import type { Store } from '@/store/store'

export class SiteForm<T extends SiteFormsKey> {
  data: Ref<FormData<T>>
  readonly initData: string
  clearable = ref(false)
  status: Ref<'new' | 'sent' | 'error' | 'departs'> = ref('new')
  serverMessage?: string
  formKey: SiteFormsKey
  item: (typeof siteForm)[T]
  tries = ref(0)
  store: Store

  constructor(formKey: SiteFormsKey, ls?: true) {
    this.store = useStore()
    this.formKey = formKey
    this.item = siteForm[formKey]
    this.initData = JSON.stringify(
      Object.fromEntries(Object.entries(this.item.form).map(([k, v]) => [k, v.initial]))
    )
    const strData = ls ? (localStorage.getItem(lsKey(formKey)) ?? this.initData) : this.initData
    this.data = ref(JSON.parse(strData))
    this.clearable.value = this.isClearable(strData)

    if (ls) {
      watch(
        this.data,
        (newVal) => {
          const str = JSON.stringify(newVal)
          localStorage.setItem(lsKey(formKey), str)
          this.clearable.value = this.isClearable(str)
        },
        {
          deep: true
        }
      )
    }
  }

  private isClearable(data?: string) {
    data ??= JSON.stringify(this.data.value)
    return data !== this.initData
  }

  clear() {
    this.data.value = JSON.parse(this.initData)
  }

  private get _checkData(): false | number {
    let errors = 0
    for (const field of Object.keys(siteForm[this.formKey].form) as (keyof FormData<T>)[]) {
      let valideField = true
      const bind = (siteForm[this.formKey].form as any)[field].bind as FormFieldBind
      const val = (this.data.value[field] ?? '') as string
      if (bind.required && val.length === 0) {
        errors++
        valideField = false
        bind.error = env.texts.requiredError
      } else if (bind.checkRules) {
        for (const rule of bind.checkRules) {
          if (!rule.formula(val)) {
            errors++
            valideField = false
            bind.error = rule.failMessage
            break
          }
        }
      }
      if (valideField && bind.error) delete bind.error
    }
    this.tries.value++
    return errors === 0 ? false : errors
  }

  async send() {
    if (this._checkData) return
    this.status.value = 'departs'
    await sleep(500) //TODO?

    try {
      this.serverMessage = await this.store.api.request(
        siteForm[this.formKey].apiKey,
        this.dataPrepare
      )
      this.status.value = 'sent'
    } catch (error) {
      this.serverMessage = (error as Error).message
      this.status.value = 'error'
    }
  }

  private get dataPrepare(): FormData<T> & { head1: string } {
    return {
      ...(Object.fromEntries(
        Object.entries(this.data.value).map(([k, v]) => [k, this.dataTransformByKey(k, v)])
      ) as FormData<T>),
      ...{ head1: this.item.head1 }
    }
  }

  private dataTransformByKey(key: string, data: string | undefined) {
    if ((this.item.form as any)[key]?.bind.options)
      return (this.item.form as any)[key].bind.options[data!]
    else return data
  }
}
