<script setup lang="ts">
import { priceRub } from '@/lib/utils'
import ButtonComponent from '../elements/ButtonComponent.vue'
import type { NoveltieItem } from '@/types'

const props = defineProps<NoveltieItem>()
function addToBasket() {
  console.log(props)
}
</script>
<template>
  <div class="Novelty">
    <div class="_image" :style="{ backgroundImage: `url(/img/items/425/${props.image})` }" />
    <div class="_badges">
      <div v-if="props.discount" class="_sale badge">%</div>
      <div v-if="props.new" class="_new badge">Новинка</div>
    </div>
    <div class="_info">
      <div class="card-price" v-html="priceRub(props.price)" />
      <div class="card-name" v-html="props.name" />
      <div class="card-brand" v-html="props.brand" />
    </div>
    <div class="_button">
      <ButtonComponent
        type="button"
        theme="two"
        text="<span class='_desctop'>Добавить в</span><span class='_mobile'>В</span> корзину"
        @on-click="addToBasket"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.Novelty {
  display: flex;
  width: 263.5px;
  flex-direction: column;
  gap: 20px;
  position: relative;
  ._image {
    width: 100%;
    aspect-ratio: 1;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
  }
  ._info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;
  }
  ._button {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  ._badges {
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
    gap: 10px;
  }
  ._sale {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background-color: $brand-main;
    color: white;
  }
  ._new {
    padding: 0 5px;
    display: flex;
    height: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background-color: $bg-dark;
    color: white;
  }
  .card-brand {
    color: $text-secondary;
  }
  button {
    height: 35px;
    :deep(._mobile) {
      display: none;
    }
  }
  @media (max-width: 600px) {
    width: 155px;
    button {
      width: 100%;
      :deep(._mobile) {
        display: inline;
      }
      :deep(._desctop) {
        display: none;
      }
    }
  }
}
</style>
