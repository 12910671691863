<script lang="ts" setup>
import { strCap } from '@/lib/utils'
import { siteRouter } from '@/router'
import { onMounted, onUnmounted } from 'vue'
import SiteItem from '../elements/SiteItemComponent.vue'
import type { MenuStructure, SiteFolderKeys, SiteItemKeys } from '@/types'
import IconComponent from '../icons/IconComponent.vue'
import { useStore } from '@/store/useStory'

const activeMenuItemEntries = Object.entries(siteRouter.activeMenuTopItemEntries)
const store = useStore()
const onClick = (event: Event) => {
  if (store.Menu.openMainMenuMore.value) {
    const openMenu = document.querySelector('#TopMenu ._block')
    if (openMenu !== event.target && !openMenu?.contains(event.target as Node))
      store.Menu.openMainMenuMore.value = false
  }
  if (store.Menu.openMainMenu.value) {
    const openMenu = document.querySelector('#TopMenu ._inline ._group._open')
    if (
      openMenu?.closest('._inline') &&
      openMenu !== event.target &&
      !openMenu?.contains(event.target as Node)
    )
      store.Menu.openMainMenu.value = null
  }
}

onMounted(() => {
  window.addEventListener('click', onClick)
  store.Menu.initTopMenu()
})

onUnmounted(() => {
  window.removeEventListener('click', onClick)
})
</script>
<template>
  <div class="TopMenu" id="TopMenu">
    <div class="horizontal-limiter">
      <div class="_content">
        <div class="_menu">
          <div class="_inline"></div>
          <div class="_block" :class="{ _open: store.Menu.openMainMenuMore.value }">
            <div class="_label" @click="store.Menu.menuMoreClick()">
              <span>Ещё</span>
              <IconComponent
                icon-id="svg-chevron"
                :icon-rotate="store.Menu.openMainMenuMore.value ? 'top' : 'down'"
              />
            </div>
            <div class="_groups">
              <div
                class="_group"
                :class="{ _open: store.Menu.openMainMenu.value === groupName }"
                :key="groupName"
                v-for="[groupName, groupItems] of activeMenuItemEntries as [
                  SiteFolderKeys,
                  MenuStructure
                ][]"
              >
                <div @click="store.Menu.menuClick(groupName)">
                  <IconComponent
                    icon-id="svg-chevron"
                    :icon-rotate="store.Menu.openMainMenu.value === groupName ? 'top' : 'down'"
                  />
                  <div v-html="strCap(groupName)" />
                </div>
                <div class="_items">
                  <div
                    :key="item"
                    v-for="item of Object.keys(groupItems) as SiteItemKeys[]"
                    @click="store.Menu.menuItemClick"
                  >
                    <SiteItem class="_item-link" :site-key="item" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="_right-block">
          <a :href="store.env.links.tg" class="_tg">
            <IconComponent icon-id="svg-telegram" />
          </a>
          <a class="_phone" :href="`tel://${store.env.phones.main}`">{{ store.env.phones.main }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.TopMenu {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: $bg-dark;
  color: white;
  font-size: 13px;
  line-height: 15.6px;
  word-wrap: break-word;
  height: 30px;
  max-height: 30px;
  ._content {
    display: flex;
    gap: 50px;
    ._menu {
      flex-grow: 1;
      display: flex;
      gap: clamp(10px, 1.4vw, 20px);
      height: 30px;
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
      ._inline {
        display: inline-flex;
        flex-wrap: wrap;
        gap: clamp(10px, 1.4vw, 20px);
        overflow: hidden;
        height: 30px;
        align-items: center;
        ._group {
          > div:first-child {
            font-weight: 500;
            display: flex;
            height: 30px;
            flex-direction: row-reverse;
            align-items: center;
            cursor: pointer;
            &:hover {
              color: $text-link;
            }
            svg {
              margin: -5px 0;
            }
          }
          ._items {
            padding: 20px 20px 10px 30px;
            display: none;
            margin: 0 0 0 -30px;
            flex-direction: column;
            position: absolute;
            background-color: $bg-dark;
            gap: 2px;
            > div {
              height: 40px;
            }
          }
          &._open ._items {
            display: flex;
          }
        }
      }
      ._block {
        display: inline-flex;
        flex-direction: column;
        ._label {
          display: flex;
          width: 45px;
          height: 30px;
          align-items: center;
          cursor: pointer;
          font-weight: 500;
          &:hover {
            color: $text-link;
          }
          svg {
            margin: -5px 0;
          }
        }
        ._groups {
          position: absolute;
          background-color: $bg-dark;
          display: none;
          flex-direction: column;
          margin: 30px 0 0 -20px;
          padding: 20px 20px 20px 20px;
          border-radius: 0 0 4px 4px;
          z-index: 1;
          gap: 20px;
          ._group {
            > div:first-child {
              display: flex;
              align-items: center;
              cursor: pointer;
              &:hover {
                color: $text-link;
              }
              svg {
                margin: -5px 0;
              }
            }
            ._items {
              padding: 20px 0 10px 30px;
              display: none;
              gap: 15px;
              flex-direction: column;
            }
            &._open ._items {
              display: flex;
            }
          }
        }
        &._open ._groups {
          display: flex;
        }
      }
    }
    ._right-block {
      display: flex;
      align-items: center;
      height: 30px;
      background-color: $bg-dark;
      a {
        color: #ffffff;
        text-wrap: nowrap;
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
  ._tg {
    margin-right: 15px;
    position: relative;
    width: 20px;
    top: 1px;
  }
}
:deep(._item-link) {
  color: #ffffff;
  &:hover {
    color: $text-link;
  }
  &.router-link-active {
    color: $text-link;
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
  }
}

@media (max-width: 800px) {
  ._phone {
    display: none;
  }
  ._tg {
    margin: 0;
  }
}

@media (max-width: 480px) {
  .TopMenu {
    top: -30px;
  }
}
</style>
