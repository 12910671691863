<script setup lang="ts">
import ButtonComponent from '@/components/elements/ButtonComponent.vue'
import { onBeforeUnmount, onMounted, ref } from 'vue'

interface Props {
  name: string
  orientation?: 'horizontal' | 'vertical'
  transitionDuration?: number
  clear?: true
  nextTimeout?: number
  gapMob?: number
  gapDesk?: number
}

const props = withDefaults(defineProps<Props>(), {
  orientation: 'horizontal',
  transitionDuration: 500,
  gapDesk: (p) => (!p.orientation || p.orientation === 'horizontal' ? 30 : 20),
  gapMob: (p) => (!p.orientation || p.orientation === 'horizontal' ? 15 : 20)
})

let underTransition = false

const itemCurrentIndex = ref(0)
const itemsCount = ref(0)

function stopRotate() {
  clearInterval(nextTimer)
}

const touchStartPos = { x: 0, y: 0 }
function touchStart(event: TouchEvent) {
  touchStartPos.x = event.touches[0].clientX
  touchStartPos.y = event.touches[0].clientY
  stopRotate()
}

function moveContent(event: TouchEvent) {
  const trend =
    props.orientation === 'horizontal'
      ? touchStartPos.x - event.touches[0].clientX
      : touchStartPos.y - event.touches[0].clientY
  const inverseTrend =
    props.orientation === 'horizontal'
      ? touchStartPos.y - event.touches[0].clientY
      : touchStartPos.x - event.touches[0].clientX

  if (
    Math.abs(trend) > Math.abs(inverseTrend) &&
    Math.abs(trend) > 3 &&
    (props.orientation === 'horizontal' ||
      (event.touches[0].clientY > content.value?.parentElement!.getBoundingClientRect().top! &&
        event.touches[0].clientY < content.value?.parentElement!.getBoundingClientRect().bottom!))
  ) {
    event.preventDefault()
    listItems(trend > 0 ? 'forward' : 'backward')
  }
}

// TODO?
function scrollContent(event: WheelEvent) {
  const trend = props.orientation === 'horizontal' ? event.deltaX : event.deltaY
  if (trend !== 0) event.preventDefault()
  if (Math.abs(trend) > 3) {
    stopRotate()
    listItems(trend > 0 ? 'forward' : 'backward')
  }
}

const content = ref<HTMLElement | null>(null)

let nextTimer: any

onMounted(() => {
  if (props.nextTimeout) {
    nextTimer = setInterval(() => listItems('forward'), props.nextTimeout)
  }
  itemsCount.value = content.value?.childElementCount ?? 0
  content.value?.addEventListener('wheel', scrollContent, { passive: false })
  content.value?.addEventListener('touchmove', moveContent, { passive: false })
  content.value?.addEventListener('touchstart', touchStart, { passive: false })
})

onBeforeUnmount(() => {
  clearInterval(nextTimer)
  content.value?.removeEventListener('wheel', scrollContent)
  content.value?.removeEventListener('touchmove', moveContent)
  content.value?.removeEventListener('touchstart', touchStart)
})

function listItems(direction: 'backward' | 'forward') {
  if (
    underTransition ||
    !content.value?.lastElementChild ||
    !content.value?.firstElementChild ||
    content.value.childElementCount < 2
  )
    return
  underTransition = true

  const gap = parseFloat(getComputedStyle(content.value).gap.replace('px', ''))
  if (direction === 'forward') {
    if (itemsCount.value > 1)
      itemCurrentIndex.value = (itemCurrentIndex.value + 1) % itemsCount.value
    if (props.orientation === 'horizontal') {
      content.value.style.transition = `left ${props.transitionDuration}ms ease`
      const offset = content.value.firstElementChild.getBoundingClientRect().width + gap
      content.value.append(content.value.firstElementChild.cloneNode(true))
      content.value.style.left = `-${offset}px`
      setTimeout(() => {
        if (!content.value) return
        content.value.style.transition = ''
        content.value.style.left = '0'
        content.value.lastElementChild!.remove()
        content.value.append(content.value!.firstElementChild!)
        underTransition = false
      }, props.transitionDuration)
    } else {
      content.value.style.transition = `top ${props.transitionDuration}ms ease`
      const offset = content.value.firstElementChild.getBoundingClientRect().height + gap
      content.value.append(content.value.firstElementChild.cloneNode(true))
      content.value.style.top = `-${offset}px`
      setTimeout(() => {
        content.value!.style.transition = ''
        content.value!.style.top = '0'
        content.value!.lastElementChild!.remove()
        content.value!.append(content.value!.firstElementChild!)
        underTransition = false
      }, props.transitionDuration)
    }
  } else {
    if (itemsCount.value > 1)
      itemCurrentIndex.value = (itemCurrentIndex.value - 1 + itemsCount.value) % itemsCount.value
    if (props.orientation === 'horizontal') {
      const offset = content.value.lastElementChild.getBoundingClientRect().width + gap
      content.value.style.left = `-${offset}px`
      content.value.prepend(content.value.lastElementChild.cloneNode(true))
      getComputedStyle(content.value).left
      content.value.style.transition = `left ${props.transitionDuration}ms ease`
      content.value.style.left = '0'
      setTimeout(() => {
        content.value!.style.transition = ''
        content.value!.firstElementChild?.remove()
        content.value!.prepend(content.value!.lastElementChild!)
        underTransition = false
      }, props.transitionDuration)
    } else {
      const offset = content.value.lastElementChild.getBoundingClientRect().height + gap
      content.value.style.top = `-${offset}px`
      content.value.prepend(content.value.lastElementChild.cloneNode(true))
      getComputedStyle(content.value).top
      content.value.style.transition = `top ${props.transitionDuration}ms ease`
      content.value.style.top = '0'
      setTimeout(() => {
        content.value!.style.transition = ''
        content.value!.firstElementChild?.remove()
        content.value!.prepend(content.value!.lastElementChild!)
        underTransition = false
      }, props.transitionDuration)
    }
  }
}
</script>
<template>
  <div class="Slider" @mousedown="stopRotate">
    <div class="_head" v-if="!props.clear">
      <h2 v-html="props.name" />
      <div class="_list">
        <ButtonComponent
          theme="icon"
          text="назад"
          :icon="{ iconId: 'svg-chevron', iconRotate: 'left', iconWidth: 32 }"
          @on-click="listItems('backward')"
        />
        <ButtonComponent
          theme="icon"
          text="вперёд"
          :icon="{ iconId: 'svg-chevron', iconRotate: 'right', iconWidth: 32 }"
          @on-click="listItems('forward')"
        />
      </div>
    </div>
    <div
      class="_content-holder"
      :class="{
        _vertical: props.orientation === 'vertical',
        _horizontal: props.orientation === 'horizontal'
      }"
    >
      <div
        ref="content"
        class="_content"
        :class="{
          _vertical: props.orientation === 'vertical',
          _horizontal: props.orientation === 'horizontal'
        }"
      >
        <slot />
      </div>
    </div>
    <div v-if="props.clear" class="_list-count">
      <ButtonComponent
        theme="icon"
        text="назад"
        :icon="{ iconId: 'svg-chevron', iconRotate: 'left', noCalculate: true }"
        @on-click="listItems('backward')"
      />
      <span class="_index" v-html="itemCurrentIndex + 1" /><span>&nbsp;/&nbsp;</span
      ><span class="_count" v-html="itemsCount" />
      <ButtonComponent
        theme="icon"
        text="вперёд"
        :icon="{ iconId: 'svg-chevron', iconRotate: 'right', noCalculate: true }"
        @on-click="listItems('forward')"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.Slider {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 30px;
  ._list-count {
    position: absolute;
    top: calc(250 / 326 * 100%);
    left: calc(44 / 1144 * 100%);
    display: flex;
    align-items: center;
    font-size: clamp(0px, 14 * 100vw / 1440, 14px);
    line-height: clamp(0px, 40 * 100vw / 1440, 40px);
    :deep(button) {
      color: $text-contrast;
      margin: 0 -4px;
      svg {
        width: clamp(0px, 40 * 100vw / 1440, 40px);
        aspect-ratio: 1;
      }
    }
    > span {
      display: inline-block;
      position: relative;
      width: clamp(0px, 15 * 100vw / 1440, 15px);
      color: $text-disabled;
      &._index {
        text-align: right;
      }
      &._count {
        left: -1px;
      }
    }
  }
  ._head {
    position: relative;
    ._list {
      position: absolute;
      top: -5px;
      right: 0;
      button {
        color: $text-contrast;
      }
    }
  }
  ._content-holder {
    overflow: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &._vertical {
      ._content {
        flex-direction: column;
        gap: calc(v-bind(gapDesk) * 1px);
      }
    }
    &._horizontal {
      ._content {
        gap: clamp(5px, calc(v-bind(gapDesk) * 100vw / 1440), calc(v-bind(gapDesk) * 1px));
        > * {
          overflow-x: auto;
          flex-shrink: 0;
          flex-grow: 0;
          white-space: nowrap;
          * {
            white-space: normal;
          }
        }
      }
    }
  }
  ._content {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    position: relative;
  }
  @media (max-width: 600px) {
    ._content-holder ._content {
      gap: calc(v-bind(gapMob) * 1px) !important;
    }
    ._head ._list {
      display: none;
    }
  }
  @media (max-width: 800px) {
    ._list-count {
      display: none;
    }
  }
}
</style>
