<script setup lang="ts">
import { type AboutUsItem } from '@/types'
import ButtonComponent from '../elements/ButtonComponent.vue'

const props = defineProps<AboutUsItem>()
</script>
<template>
  <div class="AboutUsItem">
    <div class="_desctop">
      <div class="_img" :style="{ backgroundImage: `url(/img/smi/78/${props.image})` }" />
      <div class="_content">
        <div class="_topic">
          <h3 v-html="props.topic" />
          <div class="body3" v-html="props.source" />
        </div>
        <div class="body3" v-html="props.date" />
        <div class="_link">
          <a :href="props.url">
            <ButtonComponent
              theme="two"
              size="small"
              text="Подробнее"
              :icon="{
                iconId: 'svg-arrow-tl',
                iconWidth: 15,
                embedded: { position: 'right' }
              }"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="_mobile">
      <div class="body3" v-html="props.date" />
      <h5 v-html="props.topic" />
      <a :href="props.url" v-html="props.source" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.AboutUsItem {
  ._desctop {
    display: flex;
    align-items: center;
    gap: 15px;
    min-height: 78px;
    ._img {
      flex-shrink: 0;
      width: 78px;
      aspect-ratio: 1;
      background-repeat: no-repeat;
      background-size: 78px;
    }
    .body3 {
      color: $text-secondary;
    }
    ._content {
      flex-grow: 1;
      display: flex;
      gap: 30px;
      ._topic {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
  ._mobile {
    .body3 {
      color: $text-secondary;
    }
    a {
      color: $text-secondary;
    }
    display: none;
    flex-direction: column;
    position: relative;
    gap: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid $bg-medium;
    a {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @media (max-width: 900px) {
    ._desctop {
      display: none;
    }
    ._mobile {
      display: flex;
    }
  }
}
</style>
